/**
 * return an array with elements in the given range between min and max
 * @param {*} min
 * @param {*} max
 * @returns
 */
export const range = (min, max) => {
  const array = [];
  let j = 0;
  for (var i = min; i <= max; i++) {
    array[j] = i;
    j++;
  }
  return array;
};
