<template>
  <div class="grid-document-header" ref="docHeader">
    <div class="document-logo">
      <div class="document-author-logo">
        <img src="../../assets/qps.png" alt="SSP Elektro GmbH" />
      </div>
    </div>

    <div class="document-header-right">
      <div class="document-author-address">
        Q Power Solution GmbH
        <br />Münchner Str. 12 <br />85375 Neufahrn
        <br />
        <br />+49 (0) 8165 907 49 93 <br />
        office@ssp-elektro.com <br />www.ssp-elektro.com
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrintHeaderSSP',
  components: {},
  props: {
    offer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.grid-document-header {
  margin: 1cm 1cm 0.5cm 1cm;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 1em;
}
.document-logo {
  text-align: left;
}
.document-logo img {
  width: 200px;
}
.document-header-right {
  text-align: right;
}

.document-author-address {
  //   font-weight: 700;
  line-height: 1.2em;
  text-align: right;
  font-size: 11px;
  color: $gray-600;
}
</style>
