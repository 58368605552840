<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>1.5 {{ $t('roofLayout') }}</h2>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('roofSidesAssignedState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.roofSidesAssignedState) }}</div>
        <div>{{ $t('roofSidesMeasurements_description') }}</div>
        <div>{{ project.measurement.pitchedRoof.roofSidesMeasurements }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.roofSidesAttachments"
      fieldName="roofSidesAttachments"
      fieldDescription="roofSidesAttachments_description"
      :maxImages="4"
    />

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('roofSidesShadowsState') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('roofSidesShadowsState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.roofSidesShadowsState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      v-if="project.measurement.pitchedRoof.roofSidesShadowsState !== 'NO'"
      :attachments="project.roofSidesShadowsAttachments"
      fieldName="roofSidesShadowsAttachments"
      fieldDescription="roofSidesShadowsAttachments_description"
      :maxImages="2"
    />
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
