<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>2.1 {{ $t('hak') }}</h2>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('hakLocationState') }}</div>
        <div>{{ $t(project.measurement.ac.hakLocationState) }}</div>
        <div>{{ $t('hakLockedState') }}</div>
        <div>{{ $t(project.measurement.ac.hakLockedState) }}</div>
        <div>{{ $t('hakToZsDistance') }}</div>
        <div>{{ project.measurement.ac.hakToZsDistance }}</div>
        <div>{{ $t('fireServiceSwitchPresentState') }}</div>
        <div>{{ $t(project.measurement.ac.fireServiceSwitchPresentState) }}</div>
      </div>
    </div>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('hakAndZsSameRoomState') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('hakAndZsSameRoomState') }}</div>
        <div>{{ $t(project.measurement.ac.hakAndZsSameRoomState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      v-if="project.measurement.ac.hakAndZsSameRoomState === 'NO'"
      :attachments="project.hakAndZsSameRoomAttachments"
      fieldName="hakAndZsSameRoomAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      :attachments="project.hakAttachments"
      fieldName="hakAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      v-if="project.hakOptionalAttachments || project.measurement.ac.hakNotes"
      :attachments="project.hakOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="2"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.ac.hakNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
