<template>
  <div class="content-box">
    <div class="content-box-header">
      <h3>{{ $t(fieldName) }}</h3>
    </div>
    <slot></slot>
    <div v-if="fieldDescription" class="wide">
      <div>{{ $t(fieldDescription) }}</div>
    </div>
    <div class="image-grid">
      <div v-for="i in range(1, maxImages)" :key="i">
        <img v-if="attachments[i - 1]" :src="thumbnail(attachments[i - 1])" />
      </div>
    </div>
  </div>
</template>

<script>
import { range } from '@/helpers/math.js';

export default {
  components: {},
  props: {
    attachments: null,
    maxImages: { type: Number, default: 4 },
    fieldName: { type: String, default: '' },
    fieldDescription: { type: String, default: null },
  },
  computed: {},
  methods: {
    /**
     * some paths come without scheme, prefix with https://
     * @todo DRY
     */
    sanitizePath(path) {
      if (path.indexOf('https://') != 0) {
        return 'https://' + path;
      }
      return path;
    },

    /**
     * return the thumbnail if available
     * @todo DRY
     */
    thumbnail(attachment) {
      if (attachment.thumbnail) {
        return this.sanitizePath(attachment.thumbnail);
      }
      return this.sanitizePath(attachment.path);
    },
    range,
  },
};
</script>
<style lang="scss">
.image-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
}

.image-grid div {
  // background: $enpal-grey-middle;
  border-right: 1px solid $enpal-grey-dark;
  border-bottom: 1px solid $enpal-grey-dark;
  padding: 0.5em;
  text-align: center;
}

.image-grid div:nth-child(odd) {
  border-left: 1px solid $enpal-grey-dark;
}

.wide {
  line-height: 16px;
  padding: 0.4em 0.8em;
  background-color: $enpal-grey-light;
  border-right: 1px solid $enpal-grey-dark;
  border-left: 1px solid $enpal-grey-dark;
  border-bottom: 1px solid $enpal-grey-dark;
}
</style>
