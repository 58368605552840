<template>
  <div class="content-header">
    <div class="content-header-line">
      <h1>
        <slot name="header"></slot>
      </h1>

      <div class="date">
        <div>Datum: {{ $date() }}</div>
      </div>
    </div>
    <div class="system-overview-grid">
      <div>Projekt #</div>
      <div>{{ project.employerProjectNumber }}</div>

      <div>Anlagengröße</div>
      <div>{{ formatSolarPanelsOverallPower(project) }} kWp</div>

      <div>Kunde</div>
      <div>{{ project.customer.firstname }} {{ project.customer.lastname }}</div>

      <div>Anzahl Module</div>
      <div>{{ project.solarPlant.solarPanelCount }}</div>

      <div>Straße</div>
      <div>
        {{ project.customer.street }} {{ project.customer.streetNumber
        }}{{ project.customer.streetNumberSuffix }}
      </div>

      <div>Wechselrichter</div>
      <div>
        <span v-if="project.solarPlant.inverter1">{{ project.solarPlant.inverter1.type }}</span>
      </div>

      <div>Ort</div>
      <div>{{ project.customer.zip }} {{ project.customer.city }}</div>

      <div>Speicher</div>
      <div v-if="project.solarPlant.battery">{{ project.solarPlant.battery.type }}</div>
      <div v-else>-</div>
    </div>
  </div>
</template>

<script>
import { formatSolarPanelsOverallPower, solarPlantOverallPower } from '@/helpers/projects';

export default {
  name: 'PrintHeaderSSP',
  components: {},
  props: {
    project: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    formatSolarPanelsOverallPower,
    solarPlantOverallPower,
  },
};
</script>

<style lang="scss" scoped>
$document-padding-left: 1cm;
$document-padding-right: 1cm;
$document-padding-top: 1cm;
$document-padding-bottom: 1cm;
$qcells-border: #999;

/** Content Header */
.content-header {
  margin: 0.5cm $document-padding-right 0.5cm $document-padding-left;
}
.content-header h1 {
  font-size: 1.2em;
  font-weight: bold;
}

.content-header-line {
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
}

/** System Overview */
.system-overview-grid {
  // font-size: 0.9em;
  display: grid;
  grid-template-columns: auto 1fr auto 1fr;
  row-gap: 0.1em;
  column-gap: 0.5em;
}
.system-overview-grid div:nth-child(even) {
  font-weight: bold;
}
</style>
