<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>
    <div class="content-box">
      <div class="content-box-header">
        <h2>2.3 {{ $t('electric_meter') }}</h2>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('electricMeterCount') }}</div>
        <div>{{ $t(project.measurement.ac.electricMeterCount) }}</div>
        <div>{{ $t('electricMeterList') }}</div>
        <div>{{ project.measurement.ac.electricMeterList }}</div>
        <div>{{ $t('intermediateMeterRequesteddState') }}</div>
        <div>{{ $t(project.measurement.ac.intermediateMeterRequesteddState) }}</div>
        <div>{{ $t('meterCombinationOrCascadeRequestedState') }}</div>
        <div>{{ $t(project.measurement.ac.meterCombinationOrCascadeRequestedState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.mainElectricMeterAttachments"
      fieldName="mainElectricMeterAttachments"
      :maxImages="2"
    />
    <AttachmentsPrintGrid
      :attachments="project.seconaryElectricMeterAttachments"
      fieldName="seconaryElectricMeterAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      v-if="
        project.electricMeterOptionalAttachments ||
          project.measurement.ac.electricMeterOptionalNotes
      "
      :attachments="project.electricMeterOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="2"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.ac.electricMeterOptionalNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
