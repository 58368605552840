<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <!-- Dach Innen Ziegel vermörtelt? -->
    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('innerRoof') }} {{ $t('bricksPlastered') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('bricksPlastered') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.bricksPlasteredState) }}</div>
      </div>
    </div>

    <!-- Foto vermörtelte Ziegel -->
    <AttachmentsPrintGrid
      v-if="project.measurement.pitchedRoof.bricksPlasteredState !== 'NO'"
      :attachments="project.bricksPlasteredAttachments"
      fieldName="bricksPlasteredAttachments"
      fieldDescription="bricksPlasteredAttachments"
      :maxImages="2"
    />

    <!-- Dach Innen Pappdocken vorhanden? -->
    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('innerRoof') }} {{ $t('cardboardPoppetState') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('cardboardPoppetState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.cardboardPoppetState) }}</div>
      </div>
    </div>

    <!-- Foto Pappdocken -->
    <AttachmentsPrintGrid
      v-if="project.measurement.pitchedRoof.cardboardPoppetState !== 'NO'"
      :attachments="project.cardboardPoppetAttachments"
      fieldName="cardboardPoppetAttachments"
      fieldDescription="cardboardPoppetAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      v-if="project.pitchedRoofOptionalAttachments || project.measurement.pitchedRoof.notes"
      :attachments="project.pitchedRoofOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="2"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.pitchedRoof.notes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';
import { range } from '@/helpers/math.js';

export default {
  name: 'MeasurementPrintPage1',
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: null },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
  methods: { range },
};
</script>
