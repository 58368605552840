<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSP />
      </slot>
    </template>

    <PrintProjectHeaderSSP :project="project">
      <template v-slot:header>
        <slot>
          {{ $t('measurementReport') }}
        </slot>
      </template>
    </PrintProjectHeaderSSP>

    <div class="content-box">
      <div class="content-box-header">
        <h2>1.1 {{ $t('flatRoof') }}</h2>
      </div>

      <div class="content-box-header">
        <h3>{{ $t('roof_type') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('roof_type') }}</div>
        <div>{{ $t(project.measurement.roofType) }}</div>
      </div>
    </div>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('flatRoofMeasurements') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('buildingHeight') }}</div>
        <div>{{ project.measurement.flatRoof.buildingHeight }}</div>
        <div>{{ $t('roofDimension') }}</div>
        <div>{{ project.measurement.flatRoof.roofDimension }}</div>
        <div>{{ $t('flatRoofPitchInDegrees') }}</div>
        <div>{{ project.measurement.flatRoof.flatRoofPitchInDegrees }}</div>
      </div>
    </div>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('flatRoofingMaterialType') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('flatRoofingMaterialType') }}</div>
        <div>{{ $t(project.measurement.flatRoof.flatRoofingMaterialType) }}</div>
        <div>{{ $t('flatRoofingMaterialNotes') }}</div>
        <div>{{ project.measurement.flatRoof.flatRoofingMaterialNotes }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.flatRoofSurfaceAttachments"
      fieldName="flatRoofSurfaceAttachments"
      fieldDescription="flatRoofSurfaceAttachments_description"
      :maxImages="2"
    />

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('atticPresentState') }}</h3>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('atticPresentState') }}</div>
        <div>{{ $t(project.measurement.flatRoof.atticPresentState) }}</div>
        <div>{{ $t('atticPresentNotes') }}</div>
        <div>{{ project.measurement.flatRoof.atticPresentNotes }}</div>
      </div>
    </div>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('lightningProtectionState') }}</h3>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('lightningProtectionState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.lightningProtectionState) }}</div>
        <div>{{ $t('integrateLightningProtectionState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.integrateLightningProtectionState) }}</div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSP from '@/components/Print/PrintHeaderSSP';
import PrintProjectHeaderSSP from '@/components/Print/PrintProjectHeaderSSP';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

import { range } from '@/helpers/math.js';

export default {
  components: {
    PrintPage,
    PrintHeaderSSP,
    PrintProjectHeaderSSP,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {
    isFlatRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'FLAT_ROOF';
    },
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },
  methods: { range },
};
</script>
