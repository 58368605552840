<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>2.2 {{ $t('zs') }}</h2>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('zsLocationState') }}</div>
        <div>{{ $t(project.measurement.ac.zsLocationState) }}</div>
        <div>{{ $t('newZsRequiredState') }}</div>
        <div>{{ $t(project.measurement.ac.newZsRequiredState) }}</div>
        <div>{{ $t('zsSubDistributionRequiredState') }}</div>
        <div>{{ $t(project.measurement.ac.zsSubDistributionRequiredState) }}</div>
        <template v-if="project.measurement.ac.zsSubDistributionRequiredState === 'YES'">
          <div>{{ $t('zsSubDistributionRequiredNotes') }}</div>
          <div>{{ project.measurement.ac.zsSubDistributionRequiredNotes }}</div>
        </template>

        <div>{{ $t('netType') }}</div>
        <div>{{ $t(project.measurement.ac.netType) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.zsWithCoverAttachments"
      fieldName="zsWithCoverAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      :attachments="project.zsWithoutCoverAttachments"
      fieldName="zsWithoutCoverAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      v-if="project.zsOptionalAttachments || project.measurement.ac.zsNotes"
      :attachments="project.zsOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="2"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.ac.zsNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
