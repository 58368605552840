<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>1.2 {{ $t('rafter') }}</h2>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('rafterVisibleState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.rafterVisibleState) }}</div>
        <div>{{ $t('rafterWidth') }}</div>
        <div>{{ project.measurement.pitchedRoof.rafterWidth }} cm</div>
        <div>{{ $t('rafterGap') }}</div>
        <div>{{ project.measurement.pitchedRoof.rafterGap }} cm</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.rafterVisibleAttachments"
      fieldName="rafterVisibleAttachments"
      :maxImages="4"
    />

    <AttachmentsPrintGrid
      v-if="project.rafterOptionalAttachments || project.measurement.pitchedRoof.rafterNotes"
      :attachments="project.rafterOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="2"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.pitchedRoof.rafterNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';
import { range } from '@/helpers/math.js';

export default {
  name: 'MeasurementPrintPage1',
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: null },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
  methods: { range },
};
</script>
