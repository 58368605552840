<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>2.4 {{ $t('locationAndCable') }}</h2>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('acInstallationLocationState') }}</div>
        <div>{{ $t(project.measurement.ac.acInstallationLocationState) }}</div>
        <div>{{ $t('mateboxToZsDistance') }}</div>
        <div>{{ project.measurement.ac.mateboxToZsDistance }}</div>
        <div>{{ $t('breakthroughCountState') }}</div>
        <div>{{ $t(project.measurement.ac.breakthroughCountState) }}</div>
        <div>{{ $t('cabelPathDescription') }}</div>
        <div>{{ project.measurement.ac.cabelPathDescription }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.acInstallationLocationAttachments"
      fieldName="acInstallationLocationAttachments"
      fieldDescription="acInstallationLocationAttachments_description"
      :maxImages="4"
    />

    <AttachmentsPrintGrid
      v-if="
        project.locationAndCablePathOptionalAttachments ||
          project.measurement.ac.locationAndCablePathNotes
      "
      :attachments="project.locationAndCablePathOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="2"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.ac.locationAndCablePathNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
