<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>1.3 {{ $t('outerRoof') }}</h2>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('eavesHeight1InMeters') }}</div>
        <div>{{ project.measurement.pitchedRoof.eavesHeight1 }}</div>
        <div>{{ $t('eavesHeight2InMeters') }}</div>
        <div>{{ project.measurement.pitchedRoof.eavesHeight2 }}</div>
        <div>{{ $t('rafterInsulationState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.rafterInsulationState) }}</div>
        <div>{{ $t('rafterInsulationNotes') }}</div>
        <div>{{ project.measurement.pitchedRoof.rafterInsulationNotes }}</div>
        <div>{{ $t('lightningProtectionState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.lightningProtectionState) }}</div>
        <div>{{ $t('integrateLightningProtectionState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.integrateLightningProtectionState) }}</div>
        <div>{{ $t('bricksRemoveableState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.bricksRemoveableState) }}</div>
        <template v-if="project.measurement.pitchedRoof.bricksRemoveableState === 'NO'">
          <div>{{ $t('why_not') }}</div>
          <div>{{ project.measurement.pitchedRoof.bricksRemoveableNotes }}</div>
        </template>
        <div>{{ $t('roofPitchInDegrees') }}</div>
        <div>{{ project.measurement.pitchedRoof.roofPitchInDegrees }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      v-if="project.outerRoofOptionalAttachments || project.measurement.pitchedRoof.outerRoofNotes"
      :attachments="project.outerRoofOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="4"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.pitchedRoof.outerRoofNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
