<template>
  <div class="grid-document-header" ref="docHeader">
    <div class="document-logo">
      <div class="document-author-logo">
        <img src="../../assets/qps.png" alt="SSP Elektro GmbH" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrintHeaderSSPSlim',
  components: {},
  props: {
    offer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.grid-document-header {
  margin: 0.6cm 1cm;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 1em;
}
.document-logo {
  text-align: left;
}
.document-logo img {
  width: 200px;
}
</style>
