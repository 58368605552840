<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>1.4 {{ $t('bricks') }}</h2>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('bricksConditionState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.bricksConditionState) }}</div>

        <div>{{ $t('bricks') }} {{ $t('brickManufacturer') }} - {{ $t('brickType') }}</div>
        <div>
          {{ project.measurement.pitchedRoof.brickManufacturer }} -
          {{ project.measurement.pitchedRoof.brickType }}
        </div>
        <div>{{ $t('tinBricksRequiredState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.tinBricksRequiredState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.bricksFrontAttachments"
      fieldName="bricksFrontAttachments"
      :maxImages="2"
    />
    <AttachmentsPrintGrid
      :attachments="project.bricksBackAttachments"
      fieldName="bricksBackAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      v-if="project.bricksOptionalAttachments || project.measurement.pitchedRoof.bricksNotes"
      :attachments="project.bricksOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="2"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.pitchedRoof.bricksNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
