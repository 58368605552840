<template>
  <div v-if="project && pages" class="document">
    <template v-if="isPitchedRoof">
      <!-- 1.0 Dach & 1.1 Dach Innen -->
      <MeasurementPitchedRoofPrintPage1 :pageNumber="currentPage()" :project="project" />
      <MeasurementPitchedRoofPrintPage2 :pageNumber="currentPage()" :project="project" />

      <!-- 1.2 Sparren -->
      <RafterPrintPage :pageNumber="currentPage()" :project="project" />

      <!-- 1.3 Dachaußenseite -->
      <OuterRoof :pageNumber="currentPage()" :project="project" />

      <!-- 1.4 Ziegel -->
      <Bricks :pageNumber="currentPage()" :project="project" />

      <!-- 1.5 Dachbelegung -->
      <RoofLayoutPrintPage1 :pageNumber="currentPage()" :project="project" />
      <RoofLayoutPrintPage2 :pageNumber="currentPage()" :project="project" />

      <!-- 1.5 Dachzugang -->
      <MeasurementRoofAccessPage :pageNumber="currentPage()" :project="project" />
    </template>

    <template v-if="isFlatRoof">
      <!-- 1.1 Flachdach -->
      <FlatRoofPrintPage1 :pageNumber="currentPage()" :project="project" />
      <FlatRoofPrintPage2 :pageNumber="currentPage()" :project="project" />

      <!-- 1.2 Dachzugang -->
      <MeasurementRoofAccessPage :pageNumber="currentPage()" :project="project" />
    </template>

    <!-- 2.1 HAK -->
    <Hak :pageNumber="currentPage()" :project="project" />

    <!-- 2.2 Zählerschrank -->
    <Zs :pageNumber="currentPage()" :project="project" />

    <!-- 2.3 Zähler -->
    <ElectricMeter :pageNumber="currentPage()" :project="project" />

    <!-- 2.4 Aufstellorte & Kabelweg -->
    <LocationAndCablePath :pageNumber="currentPage()" :project="project" />

    <!-- 2.5 Erdungsanlage -->
    <Earthing :pageNumber="currentPage()" :project="project" />

    <!-- 2.6 Internetanschluss -->
    <InternetConnection :pageNumber="currentPage()" :project="project" />

    <!-- 2.7 Wallbox -->
    <Wallbox :pageNumber="currentPage()" :project="project" />

    <!-- 3 Kunde -->
    <MeasurementPrintPageCustomer :pageNumber="currentPage()" :project="project" />
  </div>
  <div v-else>{{ $t('no_data_found') }}</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ExtendedProject } from '@/helpers/projects';
import FlatRoofPrintPage1 from '@/views/Measurement/FlatRoofPrintPage1';
import FlatRoofPrintPage2 from '@/views/Measurement/FlatRoofPrintPage2';
import MeasurementRoofAccessPage from '@/views/Measurement/MeasurementRoofAccessPage';
import MeasurementPrintPageCustomer from '@/views/Measurement/MeasurementPrintPageCustomer';
import Hak from '@/views/Measurement/Hak';
import Zs from '@/views/Measurement/Zs';
import ElectricMeter from '@/views/Measurement/ElectricMeter';
import LocationAndCablePath from '@/views/Measurement/LocationAndCablePath';
import Earthing from '@/views/Measurement/Earthing';
import InternetConnection from '@/views/Measurement/InternetConnection';
import Wallbox from '@/views/Measurement/Wallbox';
import OuterRoof from '@/views/Measurement/OuterRoof';
import Bricks from '@/views/Measurement/Bricks';
import RoofLayoutPrintPage1 from '@/views/Measurement/RoofLayoutPrintPage1';
import RoofLayoutPrintPage2 from '@/views/Measurement/RoofLayoutPrintPage2';
import MeasurementPitchedRoofPrintPage1 from '@/views/Measurement/MeasurementPitchedRoofPrintPage1';
import MeasurementPitchedRoofPrintPage2 from '@/views/Measurement/MeasurementPitchedRoofPrintPage2';
import RafterPrintPage from '@/views/Measurement/RafterPrintPage';

export default {
  components: {
    MeasurementPitchedRoofPrintPage1,
    MeasurementPitchedRoofPrintPage2,
    RafterPrintPage,
    FlatRoofPrintPage1,
    FlatRoofPrintPage2,
    MeasurementPrintPageCustomer,
    MeasurementRoofAccessPage,
    Hak,
    Zs,
    ElectricMeter,
    LocationAndCablePath,
    InternetConnection,
    Wallbox,
    OuterRoof,
    Bricks,
    RoofLayoutPrintPage1,
    RoofLayoutPrintPage2,
    Earthing,
  },
  data() {
    return {};
  },
  props: {
    projectNumber: {
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['getProjectByNumber']),
    pages() {
      return 1;
    },
    project() {
      const project = this.getProjectByNumber(this.number);
      if (project) {
        new ExtendedProject(project);
      }
      return project;
    },
    isFlatRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'FLAT_ROOF';
    },
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },
  methods: {
    ...mapActions(['fetchProjectByNumber']),
    currentPage() {
      this.pageCounter += 1;
      return this.pageCounter;
    },
  },
  async mounted() {
    this.number = this.projectNumber;
    await this.fetchProjectByNumber(this.number);
  },
  created() {
    this.pageCounter = 0; // create non reactive data property
  },
};
</script>
<style src="./MeasurementPrintPage.scss" lang="scss" />
