<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSP />
      </slot>
    </template>

    <PrintProjectHeaderSSP :project="project">
      <template v-slot:header>
        <slot>
          {{ $t('measurementReport') }}
        </slot>
      </template>
    </PrintProjectHeaderSSP>

    <!-- 1. Dach -->
    <div class="content-box">
      <div class="content-box-header">
        <h2>1. {{ $t('roof') }}</h2>
      </div>

      <div class="content-box-header">
        <h3>{{ $t('roof_type') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('roof_type') }}</div>
        <div>{{ $t(project.measurement.roofType) }}</div>
      </div>
    </div>

    <!-- 1.1 Dach Innen -->
    <div class="content-box">
      <div class="content-box-header">
        <h2>1.1 {{ $t('innerRoof') }}</h2>
      </div>

      <div class="content-box-header">
        <h3>{{ $t('pitched_roof_condition') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('pitched_roof_condition') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.conditionState) }}</div>
      </div>
    </div>

    <!-- Foto Giebel -->
    <AttachmentsPrintGrid
      :attachments="project.pedimentAttachments"
      fieldName="pedimentAttachments"
      fieldDescription="pedimentAttachments_description"
      :maxImages="4"
    />
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSP from '@/components/Print/PrintHeaderSSP';
import PrintProjectHeaderSSP from '@/components/Print/PrintProjectHeaderSSP';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';
import { range } from '@/helpers/math.js';

export default {
  name: 'MeasurementPrintPage1',
  components: {
    PrintPage,
    PrintHeaderSSP,
    PrintProjectHeaderSSP,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: null },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
  methods: { range },
};
</script>
