<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2 v-if="isFlatRoof">1.2 {{ $t('roofAccess') }}</h2>
        <h2 v-if="isPitchedRoof">1.6 {{ $t('roofAccess') }}</h2>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('scaffoldingPossibleState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.scaffoldingPossibleState) }}</div>
        <template v-if="project.measurement.pitchedRoof.scaffoldingPossibleState === 'NO'">
          <div>{{ $t('why_not') }}</div>
          <div>{{ project.measurement.pitchedRoof.scaffoldingPossibleNotes }}</div>
        </template>
        <div>{{ $t('rainPipeMaterialType') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.rainPipeMaterialType) }}</div>
        <div>{{ $t('rssUsableState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.rssUsableState) }}</div>
        <template v-if="project.measurement.pitchedRoof.rssUsableState === 'NO'">
          <div>{{ $t('why_not') }}</div>
          <div>{{ project.measurement.pitchedRoof.rssUsableNotes }}</div>
        </template>
        <div>{{ $t('roadBlockRequiredState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.roadBlockRequiredState) }}</div>
        <div>{{ $t('overheadLineState') }}</div>
        <div>{{ $t(project.measurement.pitchedRoof.overheadLineState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.roofAccessAttachments"
      fieldName="roofAccessAttachments"
      :maxImages="4"
    />

    <AttachmentsPrintGrid
      v-if="project.roofAccessOptionalAttachments || project.measurement.flatRoof.roofAccessNotes"
      :attachments="project.roofAccessOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="2"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.flatRoof.roofAccessNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';
import { range } from '@/helpers/math.js';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {
    isFlatRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'FLAT_ROOF';
    },
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },
  methods: { range },
};
</script>
