<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>2.5 {{ $t('earthingUnit') }}</h2>
      </div>
    </div>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('equipotentialBusBarPresentState') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('equipotentialBusBarPresentState') }}</div>
        <div>{{ $t(project.measurement.ac.equipotentialBusBarPresentState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      v-if="project.measurement.ac.equipotentialBusBarPresentState === 'YES'"
      :attachments="project.equipotentialBusBarPresentAttachments"
      fieldName="equipotentialBusBarPresentAttachments"
      :maxImages="2"
    />

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('earthingUnit') }} {{ $t('earthingPresentState') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('earthingPresentState') }}</div>
        <div>{{ $t(project.measurement.ac.earthingPresentState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      v-if="project.measurement.ac.earthingPresentState === 'YES'"
      :attachments="project.earthingPresentAttachments"
      fieldName="earthingPresentAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      v-if="project.earthingOptionalAttachments || project.measurement.ac.earthinghNotes"
      :attachments="project.earthingOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="2"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.ac.earthinghNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
