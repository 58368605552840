<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>2.7 {{ $t('wallbox') }}</h2>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('wallboxRequiredState') }}</div>
        <div>{{ $t(project.measurement.ac.wallboxRequiredState) }}</div>
        <div>{{ $t('wallboxWirelessInstallationPossibleState') }}</div>
        <div>{{ $t(project.measurement.ac.wallboxWirelessInstallationPossibleState) }}</div>
        <template v-if="project.measurement.ac.wallboxWirelessInstallationPossibleState === 'NO'">
          <div>{{ $t('why_not') }}</div>
          <div>{{ project.measurement.ac.wallboxWirelessInstallationPossibleNotes }}</div>
        </template>
        <div>{{ $t('wallboxCablePathNotes') }}</div>
        <div>{{ project.measurement.ac.wallboxCablePathNotes }}</div>
      </div>
    </div>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('heavyCurrentPresentState') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('heavyCurrentPresentState') }}</div>
        <div>{{ $t(project.measurement.ac.heavyCurrentPresentState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      v-if="project.measurement.ac.heavyCurrentPresentState === 'YES'"
      :attachments="project.heavyCurrentPresentAttachments"
      fieldName="heavyCurrentPresentAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      :attachments="project.wallboxLocationAttachments"
      fieldName="wallboxLocationAttachments"
      :maxImages="2"
    />

    <AttachmentsPrintGrid
      v-if="project.wallboxOptionalAttachments || project.measurement.ac.wallboxNotes"
      :attachments="project.wallboxOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="2"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.ac.wallboxNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
