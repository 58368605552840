<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>3. {{ $t('customer') }}</h2>
      </div>

      <div
        v-for="(field, index) in project.measurement.customer"
        :key="index"
        class="two-column-grid"
      >
        <div v-if="index !== '__typename'">
          {{ $t(index) }}
        </div>
        <div v-if="index !== '__typename'">
          <template v-if="index === 'customerSignature'">
            <img v-if="field" :src="field" :alt="$t('customerSignature')" />
          </template>
          <template v-else>{{ field }}</template>
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
