<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>2.6 {{ $t('internetConnection') }}</h2>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('internetConnectionPresentState_description') }}</div>
        <div>{{ $t(project.measurement.ac.internetConnectionPresentState) }}</div>
        <div>{{ $t('lanConnectionPresentState') }}</div>
        <div>{{ $t(project.measurement.ac.lanConnectionPresentState) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      v-if="project.internetConnectionAttachments || project.measurement.ac.internetConnectionNotes"
      :attachments="project.internetConnectionAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="2"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.ac.internetConnectionNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
