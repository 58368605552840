<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <AttachmentsPrintGrid
      :attachments="project.roofSidesSketchAttachments"
      fieldName="roofSidesSketchAttachments"
      fieldDescription="roofSidesSketchAttachments_description"
      :maxImages="4"
    />

    <AttachmentsPrintGrid
      v-if="project.roofSidesOptionalAttachments || project.measurement.pitchedRoof.roofSidesNotes"
      :attachments="project.roofSidesOptionalAttachments"
      fieldName="optionalNotesAndAttachments"
      :maxImages="4"
    >
      <div class="two-column-grid">
        <div>{{ $t('optional_notes_description') }}</div>
        <div>{{ project.measurement.pitchedRoof.roofSidesNotes }}</div>
      </div>
    </AttachmentsPrintGrid>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
};
</script>
