<template>
  <div class="page A4">
    <header ref="header">
      <slot name="header"></slot>
    </header>
    <main ref="main">
      <slot></slot>
    </main>
    <div v-if="showPageNumber" class="pageNumber">
      Seite {{ pageNumber }} <span v-if="pageCount">/ {{ pageCount }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showPageNumber: { default: true },
    showOVerallPageCount: { default: true },
    pageNumber: { default: 1 },
    pageCount: null,
  },
  created() {
    // console.log(this.pageCount);
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

/** DIN A4 Page Format */
.A4 {
  background: white;
  background-size: cover;
  background-repeat: no-repeat;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0;
  //   padding: 1cm 1.2cm 1cm 1.2cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  overflow-y: none;
  box-sizing: border-box;
  margin-bottom: 1em;
  border: 1px solid white;
}
.page {
  position: relative;
}
.pageNumber {
  position: absolute;
  bottom: 0.5cm;
  right: 1cm;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    background-size: cover;
    background-repeat: no-repeat;
    page-break-after: always;
    margin: 0;
    // padding: 1cm 1.5cm 1cm 1.5cm;
    background-position: center center;
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 13pt;
  }
}
</style>
